import dynamic             from "next/dynamic";
import { useRouter }       from "next/router";
import React, { useState } from "react";

import { useGetAdConfig }   from "_common/hooks/global";
import { useGetDeviceType } from "_common/hooks/global";
import { useGetDarkMode }   from "_common/hooks/global";
import { isPageRoute }      from "_common/utils";
import { isReelCard }       from "_common/utils/reels";

const AdvertisementDesktop = dynamic ( () => import ( "_components/features/ads/homeFeedDesktop" ) );
const Advertisement        = dynamic ( () => import ( "_components/features/ads/feedMobile" ) );
const AdSlot               = dynamic ( () => import ( "_components/features/ads/slot" ) );

const component = ( {
	idx,
	cardType
} ) => {
	const router        = useRouter ();
	const deviceType    = useGetDeviceType ();
	const isProfilePage = isPageRoute ( { router, page: "profile" } );
	const isSearchPage  = isPageRoute ( { router, page: "results" } );

	const isMobile = deviceType === "mobile";

	if ( isReelCard ( cardType ) )
		return null;

	if ( !isMobile && !isProfilePage )
		return <DesktopAd idx = { idx } />;

	if ( !isMobile )
		return null;

	if ( isProfilePage )
		return (
			<ProfilePageAd idx = { idx } />
		);

	if ( isSearchPage )
		return (
			<SearchPageAd idx = { idx } />
		);

	return null;
};

const DesktopAd = ( {
	idx
} ) => {
	const { HOME_FEED_WEB: config } = useGetAdConfig ();
	const darkMode                  = useGetDarkMode ();
	const [ loaded, setLoaded ]     = useState ( false );

	if ( !config?.enabled )
		return null;

	if ( ( idx % config?.frequency ) !== config?.offset )
		return null;

	if ( config?.adManager === "vmax" )
		return (
			<AdvertisementDesktop id = { config?.adUnitId } />
		);

	const adRendered = () => {
		setLoaded ( true );
	};

	const path = darkMode ? config?.slotId?.dark : config?.slotId?.light;

	return (
		<div style = { { display: loaded ? "block" : "none" } }>
			<AdSlot
				adRendered = { adRendered }
				id = { "gpt-passback" }
				index = { idx }
				path = { path }
				size = { config?.size }
			/>
		</div>
	);
};

const ProfilePageAd = ( {
	idx
} ) => {
	const { PROFILE_FEED_MWEB: config } = useGetAdConfig ();
	const darkMode                      = useGetDarkMode ();

	if ( !config?.enabled )
		return null;

	if ( ( idx % config?.frequency ) !== config?.offset )
		return null;

	if ( config?.adManager === "vmax" )
		return (
			<Advertisement id = { config?.adUnitId } />
		);

	const path = darkMode ? config?.slotId?.dark : config?.slotId?.light;

	return (
		<AdSlot
			id = { "gpt-passback" }
			index = { idx }
			path = { path }
			size = { config?.size }
		/>
	);
};

const SearchPageAd = ( {
	idx
} ) => {
	const { SEARCH_FEED_MWEB: config } = useGetAdConfig ();
	const darkMode                     = useGetDarkMode ();

	if ( !config?.enabled )
		return null;

	if ( ( idx % config?.frequency ) !== config?.offset )
		return null;

	if ( config?.adManager === "vmax" )
		return (
			<Advertisement id = { config?.adUnitId } />
		);

	const path = darkMode ? config?.slotId?.dark : config?.slotId?.light;

	return (
		<AdSlot
			id = { "gpt-passback" }
			index = { idx }
			path = { path }
			size = { config?.size }
		/>
	);
};

export default component;
