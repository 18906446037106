import { Carousel } from "@mantine/carousel";
import Link         from "next/link";

import { PrimaryButton }    from "_components/atoms/button";
import Icon                 from "_components/atoms/icon";
import ProductItem          from "_components/organisms/productItem";
import { useGetWebviewURL } from "_jotai/index";

import useStyles from "./styles";

const component = ( { shopData } ) => {
	const webviewURL  = useGetWebviewURL ();
	const { classes } = useStyles ();

	if ( !shopData ) {
		return null;
	}

	return (
		<div>
			<Carousel
				align = { "start" }
				classNames = { {
					container : classes.carouselContainer,
					root      : classes.carouselRoot
					// slide : classes.carouselContainer
				} }
				containScroll = { "trimSnaps" }
				dragFree = { true }
				slideGap = { 24 }
				slideSize = { "45%" }
				speed = { 0.5 }
				withControls = { false }
			>
				{
					shopData?.map ( ( data, index ) => {
						return (
							<Carousel.Slide
								key = { index }
							>
								<ProductItem
									data = { data }
								/>
							</Carousel.Slide>
						);
					} )
				}
			</Carousel>

			<Link
				href = { webviewURL }
				target = { "_blank" }
			>
				<PrimaryButton
					className = { classes.exploreBtn }
					rightSection = { (
						<Icon
							name = "exploreArrow"
							sameInBothTheme = { true }
							size = { 24 }
						/>
					) }
					size = { "24" }
					title = { "Explore More Products" }
				/>
			</Link>
		</div>
	);
};

export default component;