import { useGetDeviceType } from "_common/hooks/global";
import Image                from "_components/atoms/image";
import Typography           from "_components/atoms/typography";
import useStyles            from "_styles/molecules/gameCard";

const component = ( { data, onClick } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	const deviceType = useGetDeviceType ();
	const isMobile   = deviceType === "mobile";

	return (
		<div className = { classes.container }
			onClick = { onClick }
		>
			<Image
				className = { classes.image }
				placeholder = "/images/square-placeholder.webp"
				src = { data.image }
				type = "hdpi"
			/>

			<Typography
				className = { classes.name }
				noWrap = { isMobile }
				title = { data.name }
			/>
		</div>
	);
};

export default component;
