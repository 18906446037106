import { useShopWebview } from "_common/hooks/useShopWebview";

import Presentation from "./presentation";

const component = props => {

	const { productLink:productURL } = useShopWebview ( { productId: props?.data?._id } );

	return (
		<Presentation
			data = { props?.data }
			isLoading = { props?.isLoading }
			productURL = { productURL }
		/>
	);
};

export default component;