import Presentation from "./presentation";

const VideoCardList = ( {
	list = [],
	containerClass = "",
	from,
	activeTab = "",
	isWithoutAnimation,
	startingComponent,
	hideMenuOptions,
	cardType,
	fromRecommendations
} ) => {
	return (
		<Presentation
			activeTab          = { activeTab }
			cardType           = { cardType }
			containerClass     = { containerClass }
			from               = { from }
			fromRecommendations = { fromRecommendations }
			hideMenuOptions    = { hideMenuOptions }
			isWithoutAnimation = { isWithoutAnimation }
			list               = { list }
			startingComponent  = { startingComponent }
		/>
	);
};

export default VideoCardList;